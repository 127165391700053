import React, { useCallback } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { useApiFetch } from '../../utils/use-api-fetch.hook';
import type { PatentUrlResponse } from './types';

export type FormattedPatentDoc = {
  id: string;
  rank: number;
  country: string;
  kind: string;
  pn: string;
  pd: string;
  ti: string;
};

type PatentsTableProps = {
  initialLoad: boolean;
  loading: boolean;
  error: Error | undefined;
  results: Array<FormattedPatentDoc> | undefined;
  totalCount: number | undefined;
};
export const PatentsTable = ({
  initialLoad,
  loading,
  error,
  results,
  totalCount,
}: PatentsTableProps) => {
  const [postGetPatentUrl] = useApiFetch<PatentUrlResponse | undefined>();

  const onOpenUrl = useCallback(
    async ({
      country,
      pn: documentNumber,
      kind: documentKind,
    }: FormattedPatentDoc) => {
      const data = await postGetPatentUrl(`/api/patent/url`, {
        method: 'POST',
        body: JSON.stringify({
          documentNumber,
          documentKind,
          country,
        }),
      });

      if (data?.url) {
        window.open(data.url, '_blank')?.focus();
      }
    },
    [postGetPatentUrl]
  );

  if (loading) {
    return <p>{'Loading...'}</p>;
  }

  if (error) {
    return <p>{`Error: ${error.message}`}</p>;
  }

  return (
    <>
      {results?.length ? (
        <>
          <p>{`Showing ${results.length} of ${totalCount} results`}</p>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Publication Date</th>
                <th>Publication Number</th>
                <th>Country</th>
                <th>Title</th>
                <th>PDF</th>
              </tr>
            </thead>
            <tbody>
              {results.map((result) => (
                <tr key={result.id}>
                  <td>{result.pd}</td>
                  <td>{result.pn}</td>
                  <td>{result.country}</td>
                  <td>{result.ti}</td>
                  <td>
                    <Button
                      variant="secondary"
                      className="mr-2"
                      onClick={() => onOpenUrl(result)}
                    >
                      Open
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      ) : !initialLoad ? (
        <p>No results found</p>
      ) : (
        <></>
      )}
    </>
  );
};
