import React, { useState, useCallback } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import type { CreateUserPayload } from '../types';

interface OnboardingProps {
  submitting: boolean;
  onSubmit: (val: CreateUserPayload) => void;
}

export const Onboarding = ({ submitting, onSubmit }: OnboardingProps) => {
  const [email, setEmail] = useState<string | undefined>();
  const [firstName, setFirstName] = useState<string | undefined>();
  const [lastName, setLastName] = useState<string | undefined>();

  const submitForm = useCallback(
    (e: any) => {
      e.preventDefault();

      onSubmit({
        email,
        firstName,
        lastName,
      });
    },
    [onSubmit, email, firstName, lastName]
  );

  return (
    <>
      <p>Let's finish getting to know you!</p>
      <Form onSubmit={submitForm}>
        <Form.Group className="mb-3" controlId="formBasicFirstName">
          <Form.Label>First name</Form.Label>
          <Form.Control
            type="text"
            placeholder="First name"
            onChange={(e) => setFirstName(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicLastName">
          <Form.Label>Last name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Last name"
            onChange={(e) => setLastName(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <Form.Text className="text-muted">
            We'll never share your email with anyone else.
          </Form.Text>
        </Form.Group>
        <Button variant="primary" type="submit" disabled={submitting}>
          {submitting ? 'Submitting...' : 'Submit'}
        </Button>
      </Form>
    </>
  );
};
