import React, { useCallback, useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { useApiFetch } from '../../../utils/use-api-fetch.hook';
import { AddReviewersModal } from './add-reviewers-modal';
import type { ReviewersResponse } from './types';

export const Reviewers = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [apiFetch, { loading, data, error }] = useApiFetch<
    ReviewersResponse | undefined
  >();

  const getReviewers = useCallback(async () => {
    apiFetch('/api/reviewers');
  }, [apiFetch]);

  useEffect(() => {
    getReviewers();
  }, [getReviewers]);

  const [deactivateReviewer] = useApiFetch<any>();
  const onDeactivateReviewer = useCallback(
    async (reviewerId: number) => {
      await deactivateReviewer(`/api/reviewers/${reviewerId}/deactivate`, {
        method: 'PUT',
      });
      apiFetch('/api/reviewers');
    },
    [deactivateReviewer, apiFetch]
  );

  // const [reactivateReviewer] = useApiFetch<any>();
  // const onReactivateReviewer = useCallback(
  //   async (reviewerId: number) => {
  //     await reactivateReviewer(`/api/reviewers/${reviewerId}/reactivate`, {
  //       method: 'PUT',
  //     });
  //     apiFetch('/api/reviewers');
  //   },
  //   [reactivateReviewer, apiFetch]
  // );

  console.log('data', data);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{`Error: ${error}`}</p>;
  }

  const activeReviewers = data?.results?.filter(
    (reviewer) => reviewer.status === 'active'
  );

  return (
    <>
      <b className="mt-2">Reviewers</b>

      <Table striped bordered hover className="mt-2">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Search Queries</th>
          </tr>
        </thead>
        <tbody>
          {activeReviewers?.length ? (
            <>
              {activeReviewers.map((reviewer) => (
                <tr key={reviewer.id}>
                  <td>
                    <a href={`reviewer/${reviewer.id}`}>
                      {`${reviewer.first_name} ${reviewer.last_name}`}
                    </a>
                  </td>
                  <td>{reviewer.email}</td>
                  <td>{`${reviewer.search_queries.length} found`}</td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="outline-primary"
                        id="dropdown-basic"
                      >
                        Settings
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {reviewer.status === 'active' && (
                          <Dropdown.Item
                            onClick={() => onDeactivateReviewer(reviewer.id)}
                          >
                            Remove
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              ))}
            </>
          ) : (
            <tr>
              <td colSpan={4}>No Reviewers found</td>
            </tr>
          )}
        </tbody>
      </Table>

      <Button variant="secondary" className="mr-2" onClick={handleShow}>
        Add Reviewer
      </Button>

      <AddReviewersModal
        show={show}
        handleClose={handleClose}
        afterSubmit={getReviewers}
      />
    </>
  );
};
