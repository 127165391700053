import React from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { App } from './App';
import { Dashboard } from './pages/dashboard';
import { LoginPage } from './pages/login';
import { ReviewerPage } from './pages/reviewer';
import { AddSearchQueryPage } from './pages/add-search-query';
import { EditSearchQueryPage } from './pages/edit-search-query';

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const { isLoading, isAuthenticated } = useAuth0();
  let location = useLocation();

  if (!isLoading && !isAuthenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

const RequireNoAuth = ({ children }: { children: JSX.Element }) => {
  const { isLoading, isAuthenticated } = useAuth0();

  if (!isLoading && isAuthenticated) {
    // Redirect them to the / page
    return <Navigate to="/" replace />;
  }

  return children;
};

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<App />}>
      <Route
        path="login"
        element={
          <RequireNoAuth>
            <LoginPage />
          </RequireNoAuth>
        }
      />
      <Route
        path="/"
        element={
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        }
      />
      <Route
        path="reviewer/:reviewerId"
        element={
          <RequireAuth>
            <ReviewerPage />
          </RequireAuth>
        }
      />
      <Route
        path="reviewer/:reviewerId/search-queries/create"
        element={
          <RequireAuth>
            <AddSearchQueryPage />
          </RequireAuth>
        }
      />
      <Route
        path="reviewer/:reviewerId/search-queries/:searchQueryId/edit"
        element={
          <RequireAuth>
            <EditSearchQueryPage />
          </RequireAuth>
        }
      />
      {/* <Route path="profile" element={<Profile />} /> */}
      {/* ... etc. */}
    </Route>
  )
);
