import React, { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useApiFetch } from '../../utils/use-api-fetch.hook';
import { SearchQueries } from './search-queries.component';
import { ReviewerRecipients } from './reviewer-recipients.component';
import type { SearchQueryResponseType } from './types';

type ReviewerStatus = 'active' | 'inactive';

type ReviewerType = {
  id: number;
  user_id: number;
  email: string;
  first_name: string;
  last_name: string;
  status: ReviewerStatus;
  search_queries: SearchQueryResponseType[];
};

export const ReviewerPage = () => {
  const { reviewerId } = useParams();

  const [apiFetchReviewer, { loading: reviewerLoading, data: reviewer }] =
    useApiFetch<ReviewerType | undefined>();
  const fetchReviewer = useCallback(async () => {
    apiFetchReviewer(`/api/reviewers/${reviewerId}`);
  }, [reviewerId, apiFetchReviewer]);

  useEffect(() => {
    if (reviewerId) {
      fetchReviewer();
    }
  }, [reviewerId, fetchReviewer]);

  const reviewerName = reviewerLoading
    ? '...'
    : `${reviewer?.first_name ?? ''} ${reviewer?.last_name ?? ''} (${
        reviewer?.email
      })`;

  return (
    <Container>
      <Row>
        <h2>{reviewerName}</h2>
      </Row>

      <Row className="mt-4 pb-4">
        <ReviewerRecipients reviewerId={reviewerId} />
      </Row>

      <Row className="mt-4">
        <SearchQueries reviewerId={reviewerId} />
      </Row>
    </Container>
  );
};
