import { useCallback, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const useAppFetch = () => {
  const { getAccessTokenSilently } = useAuth0();

  const appFetch = useCallback(
    async (input: RequestInfo | URL, init?: RequestInit): Promise<Response> => {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: 'read:current_user',
      });

      return fetch(`${process.env.REACT_APP_API_SERVER_URL}${input}`, {
        ...(init ?? {}),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });
    },
    [getAccessTokenSilently]
  );

  return appFetch;
};

export const useApiFetch = <DataType>(
  props: {
    onSuccess?: (data: DataType) => void;
  } | void
): [
  (input: RequestInfo | URL, init?: RequestInit) => Promise<DataType>,
  { loading: boolean; data: DataType | undefined; error: any }
] => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<DataType | undefined>(undefined);
  const [error, setError] = useState<any>(undefined);

  const appFetch = useAppFetch();

  const apiFetch = useCallback(
    async (input: RequestInfo | URL, init?: RequestInit) => {
      setLoading(true);
      setData(undefined);
      setError(undefined);
      try {
        const response = await appFetch(input, init);

        const jsonResponse = await response.json();
        if (jsonResponse?.error) {
          throw jsonResponse.error;
        }

        setLoading(false);
        setData(jsonResponse);

        props?.onSuccess && props.onSuccess(jsonResponse);

        return jsonResponse;
      } catch (error) {
        setLoading(false);
        setError(error);
        console.error(error);
      }
    },
    [appFetch, props]
  );

  return [apiFetch, { loading, data, error }];
};
