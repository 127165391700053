import React, { useState, useEffect, useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { useApiFetch } from '../../utils/use-api-fetch.hook';
import { AddReviewerRecipientModal } from './add-reviewer-recipient-modal/add-reviewer-recipient-modal.component';
import type { ReviewerRecipientResponseType } from './types';

type ReviewerRecipientsResponse = {
  results: ReviewerRecipientResponseType[];
};

interface ReviewerRecipientsProps {
  reviewerId: string | undefined;
}
export const ReviewerRecipients = ({ reviewerId }: ReviewerRecipientsProps) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [initialLoad, setInitialLoad] = useState<boolean>(true);

  const [fetchReviewerRecipients, { loading, data, error }] = useApiFetch<
    ReviewerRecipientsResponse | undefined
  >();

  const refetchReviewerRecipients = useCallback(() => {
    fetchReviewerRecipients(`/api/reviewers/${reviewerId}/reviewer-recipients`);
  }, [fetchReviewerRecipients, reviewerId]);

  const [deleteReviewerRecipient] = useApiFetch<any>();
  const onRemoveReviewerRecipient = useCallback(
    async (reviewerRecipientId: number) => {
      await deleteReviewerRecipient(
        `/api/reviewers/${reviewerId}/reviewer-recipients/${reviewerRecipientId}`,
        {
          method: 'DELETE',
        }
      );
      refetchReviewerRecipients();
    },
    [deleteReviewerRecipient, refetchReviewerRecipients, reviewerId]
  );

  useEffect(() => {
    if (reviewerId) {
      setInitialLoad(false);
      refetchReviewerRecipients();
    }
  }, [refetchReviewerRecipients, reviewerId]);

  return (
    <>
      <h3>Additional Recipients:</h3>
      <p>
        These emails will be CC'd on <b>all</b> emails going to the reviewer.
      </p>

      {loading ? (
        <p>{'Loading...'}</p>
      ) : error ? (
        <p>{`Error: ${error}`}</p>
      ) : (
        <>
          {data?.results ? (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Email</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data?.results?.length && !initialLoad ? (
                  data.results.map((result) => (
                    <tr key={result.id}>
                      <td>
                        <p>{result.email}</p>
                      </td>
                      <td>
                        <Button
                          variant="outline-primary"
                          className="mr-2"
                          onClick={() => onRemoveReviewerRecipient(result.id)}
                        >
                          Remove
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={2}>No results found</td>
                  </tr>
                )}
              </tbody>
            </Table>
          ) : (
            <></>
          )}

          <Button variant="secondary" className="mr-2" onClick={handleShow}>
            Add additional Recipient
          </Button>

          {reviewerId && (
            <AddReviewerRecipientModal
              show={show}
              reviewerId={reviewerId}
              handleClose={handleClose}
              afterSubmit={refetchReviewerRecipients}
            />
          )}
        </>
      )}
    </>
  );
};
