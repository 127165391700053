import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { router } from './router';

import './index.css';
// import 'bootstrap/dist/css/bootstrap.min.css'; // uncomment if not able to load bootstrap from CDN

const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;
const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN ?? '';
const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID ?? '';
const AUTH0_REDIRECT_URL = process.env.REACT_APP_AUTH0_REDIRECT_URL;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Auth0Provider
    domain={AUTH0_DOMAIN}
    clientId={AUTH0_CLIENT_ID}
    redirectUri={AUTH0_REDIRECT_URL}
    audience={AUTH0_AUDIENCE}
    scope="read:current_user write:current_user"
  >
    <RouterProvider router={router} />
  </Auth0Provider>
);
