import React from 'react';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { useAuth0 } from '@auth0/auth0-react';

export const LoginPage = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Container>
      <Stack gap={2} className="col-md-5 mx-auto mt-5">
        <div style={{ textAlign: 'center' }} className="mb-3">
          <h1>TECHLINK</h1>
        </div>

        <Button variant="primary" onClick={() => loginWithRedirect()}>
          Login
        </Button>
        <Button
          variant="secondary"
          onClick={() => loginWithRedirect({ screen_hint: 'signup' })}
        >
          Sign up
        </Button>
      </Stack>
    </Container>
  );
};
