import React, { useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useApiFetch } from '../../../utils/use-api-fetch.hook';
import { isValidEmail } from '../../../utils/validators';
import type { ReviewerRecipientResponseType } from '../types';
import { useFormik } from 'formik';

type Fields = {
  email: string;
};

// A custom validation function. This must return an object
// which keys are symmetrical to our values/initialValues
const validate = (values: Fields) => {
  const errors: Partial<Fields> = {};
  if (!values.email) {
    errors.email = 'Required';
  } else if (isValidEmail(values.email)) {
    errors.email = 'Invalid email address';
  }

  return errors;
};

type AddReviewerRecipientModalProps = {
  show: boolean;
  reviewerId: string;
  handleClose: () => void;
  afterSubmit: () => void;
};
export const AddReviewerRecipientModal = ({
  show,
  reviewerId,
  handleClose,
  afterSubmit,
}: AddReviewerRecipientModalProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [apiPostReviewerRecipient, { loading: submitting, error: postError }] =
    useApiFetch<ReviewerRecipientResponseType | undefined>();

  const createReviewer = useCallback(
    async ({ email }: Fields) => {
      console.log('SAVING', { email });
      await apiPostReviewerRecipient(
        `/api/reviewers/${reviewerId}/reviewer-recipients/create`,
        {
          method: 'POST',
          body: JSON.stringify({
            reviewerId,
            email,
          }),
        }
      );
      handleClose();
      afterSubmit();
    },
    [reviewerId, apiPostReviewerRecipient, handleClose, afterSubmit]
  );

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validate,
    onSubmit: createReviewer,
  });

  return (
    <Modal show={show} onHide={handleClose}>
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Add additional recipient</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              name="email"
              placeholder="Email"
              type="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="text-danger">{formik.errors.email}</div>
            ) : null}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" variant="primary" disabled={submitting}>
            {submitting ? 'Submitting...' : 'Submit'}
          </Button>
          {postError && <p>{`Error occurred: ${postError}`}</p>}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
