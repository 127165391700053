import React, { useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useApiFetch } from '../../utils/use-api-fetch.hook';
import { SearchRequeryResponse } from './types';
import {
  FormattedPatentDoc,
  PatentsTable,
} from '../../components/patents-table';
import { SearchQueryForm } from '../../components/search-query-form';

export const AddSearchQueryPage = () => {
  const { reviewerId } = useParams();
  const navigate = useNavigate();

  const [initialLoad, setInitialLoad] = useState<boolean>(true);

  const [apiFetch, { loading: fetchResultsLoading, data, error }] = useApiFetch<
    { results: Array<FormattedPatentDoc>; totalCount: number } | undefined
  >();
  const { results, totalCount } = data || {};

  const [postCreateSearchQuery, { error: createSearchQueryError }] =
    useApiFetch<SearchRequeryResponse | undefined>({
      onSuccess: () => navigate(`/reviewer/${reviewerId}`),
    });

  const onSubmit = useCallback(
    async (values: any) => {
      const fields = [
        { name: 'title', value: values.title },
        { name: 'inventor', value: values.inventor },
        { name: 'description', value: values.description }, // DEPRECATED
        { name: 'abstract', value: values.abstract },
        { name: 'claims', value: values.claims },
        {
          name: 'cpcClassification',
          value: values.cpcClassification,
        },
        { name: 'assignee', value: values.assignee },
      ].filter((field) => !!field.value);

      postCreateSearchQuery(
        `/api/reviewers/${reviewerId}/search-queries/create`,
        {
          method: 'POST',
          body: JSON.stringify({
            reviewerId,
            name: values.name,
            fields,
          }),
        }
      );
    },
    [reviewerId, postCreateSearchQuery]
  );

  const onTest = useCallback(
    (values: any) => {
      setInitialLoad(false);
      apiFetch('/api/patents/query', {
        method: 'POST',
        body: JSON.stringify({
          title: values.title,
          inventor: values.inventor,
          description: values.description, // DEPRECATED
          abstract: values.abstract,
          claims: values.claims,
          cpcClassification: values.cpcClassification,
          assignee: values.assignee,
        }),
      });
    },
    [apiFetch]
  );

  const hasTooMayResults = (totalCount ?? 0) >= 1000;

  return (
    <Container>
      <Row>
        <h2>Create new Search Query</h2>
      </Row>

      <Row>
        <SearchQueryForm
          flow="add"
          hasTooMayResults={hasTooMayResults}
          fetchResultsLoading={fetchResultsLoading}
          submitError={createSearchQueryError?.message}
          forceDisableSubmit={initialLoad}
          onTest={onTest}
          onSubmit={onSubmit}
          initialValues={{}}
        />
      </Row>

      <Row className="mt-4">
        <h3>Results:</h3>

        <PatentsTable
          initialLoad={initialLoad}
          loading={fetchResultsLoading}
          error={error}
          results={results}
          totalCount={totalCount}
        />
      </Row>
    </Container>
  );
};
