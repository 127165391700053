import React, { useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useApiFetch } from '../../../../utils/use-api-fetch.hook';
import { isValidEmail } from '../../../../utils/validators';
import type { ReviewersResponse } from '../types';
import { useFormik } from 'formik';

type Fields = {
  email: string;
  firstName: string;
  lastName: string;
};

// A custom validation function. This must return an object
// which keys are symmetrical to our values/initialValues
const validate = (values: Fields) => {
  const errors: Partial<Fields> = {};
  if (!values.firstName) {
    errors.firstName = 'Required';
  }

  if (!values.lastName) {
    errors.lastName = 'Required';
  }

  if (!values.email) {
    errors.email = 'Required';
  } else if (isValidEmail(values.email)) {
    errors.email = 'Invalid email address';
  }

  return errors;
};

type AddReviewersModalProps = {
  show: boolean;
  handleClose: () => void;
  afterSubmit: () => void;
};
export const AddReviewersModal = ({
  show,
  handleClose,
  afterSubmit,
}: AddReviewersModalProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [apiPostReviewer, { loading: submitting, error: postError }] =
    useApiFetch<ReviewersResponse | undefined>();

  const createReviewer = useCallback(
    async ({ email, firstName, lastName }: Fields) => {
      console.log('SAVING', { email, firstName, lastName });
      await apiPostReviewer('/api/reviewers/create', {
        method: 'POST',
        body: JSON.stringify({
          email,
          firstName,
          lastName,
        }),
      });
      handleClose();
      afterSubmit();
    },
    [apiPostReviewer, handleClose, afterSubmit]
  );

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
    validate,
    onSubmit: createReviewer,
  });

  return (
    <Modal show={show} onHide={handleClose}>
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Add Reviewer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              name="email"
              placeholder="Email"
              type="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="text-danger">{formik.errors.email}</div>
            ) : null}
          </Form.Group>

          <Form.Group className="mb-3" controlId="formFirstName">
            <Form.Label>First name</Form.Label>
            <Form.Control
              name="firstName"
              placeholder="First name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
            />
            {formik.touched.firstName && formik.errors.firstName ? (
              <div className="text-danger">{formik.errors.firstName}</div>
            ) : null}
          </Form.Group>

          <Form.Group className="mb-3" controlId="formLastName">
            <Form.Label>Last name</Form.Label>
            <Form.Control
              name="lastName"
              placeholder="Last name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
            />
            {formik.touched.lastName && formik.errors.lastName ? (
              <div className="text-danger">{formik.errors.lastName}</div>
            ) : null}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" variant="primary" disabled={submitting}>
            {submitting ? 'Saving...' : 'Save Changes'}
          </Button>
          {postError && <p>{`Error occurred: ${postError}`}</p>}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
