import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import { useApiFetch } from '../../utils/use-api-fetch.hook';
import type {
  SearchQueryResponseType,
  SearchFilterResponseType,
} from './types';

type SearchQueriesResponse = {
  results: SearchQueryResponseType[];
};

type SearchFilterResultsProps = {
  searchFilters: SearchFilterResponseType[];
};
const SearchFilterResults = ({ searchFilters }: SearchFilterResultsProps) => {
  const searchFilterResults = searchFilters.map(
    ({ field_name, field_value }) => `${field_name}: ${field_value}`
  );

  return (
    <div>
      {searchFilterResults.map((searchFilter, index) => (
        <p key={index}>{searchFilter}</p>
      ))}
    </div>
  );
};

interface SearchQueriesProps {
  reviewerId: string | undefined;
}
export const SearchQueries = ({ reviewerId }: SearchQueriesProps) => {
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const navigate = useNavigate();

  const [fetchSearchQueries, { loading, data, error }] = useApiFetch<
    SearchQueriesResponse | undefined
  >();

  const handleAddSearchQuery = useCallback(() => {
    navigate(`/reviewer/${reviewerId}/search-queries/create`);
  }, [navigate, reviewerId]);

  const refetchSearchQueries = useCallback(() => {
    fetchSearchQueries(`/api/reviewers/${reviewerId}/search-queries`);
  }, [fetchSearchQueries, reviewerId]);

  const [deleteSearchQuery] = useApiFetch<any>();
  const onRemoveSearchQuery = useCallback(
    async (searchQueryId: number) => {
      await deleteSearchQuery(
        `/api/reviewers/${reviewerId}/search-queries/${searchQueryId}`,
        {
          method: 'DELETE',
        }
      );
      refetchSearchQueries();
    },
    [deleteSearchQuery, refetchSearchQueries, reviewerId]
  );

  useEffect(() => {
    if (reviewerId) {
      setInitialLoad(false);
      refetchSearchQueries();
    }
  }, [refetchSearchQueries, reviewerId]);

  return (
    <>
      <h3>Search Queries:</h3>

      {loading ? (
        <p>{'Loading...'}</p>
      ) : error ? (
        <p>{`Error: ${error}`}</p>
      ) : (
        <>
          {data?.results ? (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Search Filters</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data?.results?.length && !initialLoad ? (
                  data.results.map((result) => (
                    <tr key={result.id}>
                      <td>
                        <a
                          href={`/reviewer/${reviewerId}/search-queries/${result.id}/edit`}
                        >
                          {result.name || result.id}
                        </a>
                      </td>
                      <td>
                        <SearchFilterResults
                          searchFilters={result.search_filters}
                        />
                      </td>
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="outline-primary"
                            id="dropdown-basic"
                          >
                            Settings
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => onRemoveSearchQuery(result.id)}
                            >
                              Remove
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={2}>No results found</td>
                  </tr>
                )}
              </tbody>
            </Table>
          ) : (
            <></>
          )}

          <Button
            variant="secondary"
            className="mr-2"
            onClick={handleAddSearchQuery}
          >
            Add Search Query
          </Button>
        </>
      )}
    </>
  );
};
