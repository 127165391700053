import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Outlet } from 'react-router-dom';
import { Header } from './header';

import './App.css';

export const App = () => {
  const { isAuthenticated, isLoading } = useAuth0();

  return (
    <>
      {(isAuthenticated || isLoading) && <Header />}

      {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
      <Outlet />
    </>
  );
};
